import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { slugify } from 'utils/slugify';

interface ISiteAvatar {
  width: number;
  height: number;
  className?: string;
  slug?: string;
  name: string;
}

/** renders a fan site avatar */
export const FanSiteAvatar = ({
  width,
  height,
  className,
  slug,
  name,
}: ISiteAvatar) => {
  const { currentSite } = useSite();

  const imageName = slugify(name);

  // move to site key. This is a one off.
  const isKsrPlus = currentSite?.url === '/sites/kentucky-wildcats';
  const isKsrChannel = currentSite?.url === '/college/kentucky-wildcats';
  const pathName = isKsrChannel || isKsrPlus ? 'kentucky-plus' : imageName;

  const imageSlug = slug || '-icon.png';

  return (
    <img
      alt={`${name} logo`}
      className={className}
      height={height}
      loading="lazy"
      src={`https://on3static.com/cdn-cgi/image/height=${height},width=${width}/sites/${pathName}${imageSlug}?v=25`}
      title={name}
      width={width}
    />
  );
};
