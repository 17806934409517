import { SVGProps } from 'react';

const SvgVisibility = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 21 14"
    fill="none"
    {...props}
  >
    <path
      opacity={0.7}
      d="M10.267 0C5.6 0 1.615 2.903 0 7c1.615 4.097 5.6 7 10.267 7 4.666 0 8.652-2.903 10.266-7-1.614-4.097-5.6-7-10.266-7Zm0 11.667A4.668 4.668 0 0 1 5.6 7a4.668 4.668 0 0 1 4.667-4.667A4.668 4.668 0 0 1 14.933 7a4.668 4.668 0 0 1-4.666 4.667Zm0-7.467c-1.55 0-2.8 1.25-2.8 2.8 0 1.55 1.25 2.8 2.8 2.8 1.549 0 2.8-1.25 2.8-2.8 0-1.55-1.251-2.8-2.8-2.8Z"
      fill="#1D252C"
    />
  </svg>
);

export default SvgVisibility;
