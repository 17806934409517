import { SVGProps } from 'react';

const SvgComment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      opacity={0.7}
      d="M14.4 0H1.6C.72 0 0 .72 0 1.6v9.6c0 .88.72 1.6 1.6 1.6h11.2L16 16V1.6c0-.88-.72-1.6-1.6-1.6Zm-1.6 9.6H3.2V8h9.6v1.6Zm0-2.4H3.2V5.6h9.6v1.6Zm0-2.4H3.2V3.2h9.6v1.6Z"
      fill="#1D252C"
    />
  </svg>
);

export default SvgComment;
