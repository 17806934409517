import { format as formatDateFns } from 'date-fns';
/**Takes epoch date in seconds, removes timezoneOffset. Second arg is the template for date-fns (default is 'P'). Returns formatted date. */
export const epochDateConverter = (date: number, template?: string) => {
  const dt = new Date(date * 1000);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000,
  );
  const formattedDate = formatDateFns(dtDateOnly, template ?? 'P');

  return formattedDate;
};

export const epochUserTimeConverter = (
  date?: number,
  type: 'date' | 'time' = 'time',
  template?: string,
) => {
  const unixTimestamp = date ?? 0;
  const timestampInMilliseconds = unixTimestamp * 1000;
  const passDate = new Date(timestampInMilliseconds);

  // If a template is provided, use date-fns to format the date
  if (template) {
    return formatDateFns(passDate, template);
  }

  // Get the user's local time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a formatter with the user's time zone
  const formatterOptions: Intl.DateTimeFormatOptions =
    type === 'date'
      ? {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: userTimeZone,
        }
      : { hour: 'numeric', minute: 'numeric', timeZone: userTimeZone };

  const formatter = new Intl.DateTimeFormat('en-US', formatterOptions);

  return formatter.format(passDate);
};
