import type { ThreadLatestPostResponse } from '@on3/api';
import { On3IconComment, On3IconVisibility } from '@on3/icons';
import { toFriendlyDate } from '@on3/ui-lib/src/utils/date';
import { Text } from 'components/UI/Typography/Text';

import styles from './Forums.module.scss';

export const ForumItem = ({
  lastPostAuthorUsername,
  lastPostDate,
  replyCount,
  threadAuthorUsername,
  threadDate,
  title,
  url,
  viewCount,
}: ThreadLatestPostResponse) => {
  return (
    <li className={styles.postWrapper}>
      <div>
        <a href={`${url}/`}>
          <Text className={styles.threadlink} component="div">
            <Text className={styles.postTitle} color="primary" variant="h5">
              {title}
            </Text>
            <Text className={styles.postAuthorContainer} variant="caption">
              <span>
                <span className={styles.name}>by {threadAuthorUsername}</span>
                <span>
                  &#8226; {toFriendlyDate(threadDate && threadDate * 1000)}
                </span>
              </span>
              <span className={styles.comments}>
                <On3IconComment height={16} width={16} /> {replyCount}
              </span>
              <span className={styles.views}>
                <On3IconVisibility height={16} width={16} /> {viewCount}
              </span>
            </Text>
          </Text>
        </a>
        <a href={`${url}/`}>
          <Text className={styles.lastPostContainer} variant="caption">
            <span>
              Last: {toFriendlyDate(lastPostDate && lastPostDate * 1000)}
            </span>
            <span className={styles.lastPostAuthor}>
              By {lastPostAuthorUsername}
            </span>
          </Text>
        </a>
      </div>
    </li>
  );
};
