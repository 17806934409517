import type { HeadlineResponse } from '@on3/api';
import clsx from 'clsx';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './ArticleHeadliner.module.scss';

interface IArticleHeadlinerProps extends HeadlineResponse {
  isDaily?: boolean;
}

export const ArticleHeadliner = ({
  featuredImage,
  title,
  fullUrl,
  isDaily = false,
  primaryCategory,
}: IArticleHeadlinerProps) => {
  return (
    <article className={styles.articleContainer}>
      {featuredImage && (
        <TextLink className={styles.imagelink} href={fullUrl}>
          <ResizedImage
            className={styles.image}
            {...featuredImage}
            fit="cover"
            gravity="0.5x0.5"
            height={157}
            quality={90}
            width={300}
          />
        </TextLink>
      )}
      <div>
        <Text className={styles.category} variant="subtitle1">
          {primaryCategory?.name}
        </Text>
        <TextLink color="primary" href={fullUrl} underline="none">
          <Text
            className={clsx({
              [styles.title]: true,
              [styles.dailyTitle]: isDaily,
            })}
            color="primary"
            variant={isDaily ? 'h6' : 'h5'}
          >
            {title}
          </Text>
        </TextLink>
      </div>
    </article>
  );
};
