import type {
  AssetResponse,
  DefaultAssetResponse,
  ScoreboardResponse,
} from '@on3/api';
import { GameStatuses } from '@on3/api';
import { epochUserTimeConverter } from '@on3/ui-lib/src/utils/epochDateConverter';

const getGameTime = (game: ScoreboardResponse): string => {
  const { startDateUtc, startTime, startTimeTbd } = game || {};

  if (startTimeTbd) return 'TBD';
  if (startDateUtc) return epochUserTimeConverter(startDateUtc);
  if (startTime) return startTime;

  return 'TBD';
};

const getGameStatuses = () => {
  const formatLabel = (key: string) => {
    if (key === GameStatuses.Completed) return 'Final';

    return key.replace(/([A-Z])/g, ' $1').trim();
  };

  return Object.entries(GameStatuses)
    .filter(([_, v]) => v !== GameStatuses.Unknown)
    .map(([key, value]) => ({
      label: formatLabel(key),
      value,
    }));
};

const getPredictionOutcome = (
  game: ScoreboardResponse,
): boolean | undefined => {
  if (game.statusEnum !== GameStatuses.Completed) return;

  const {
    homeTeamScorePrediction: htp,
    awayTeamScorePrediction: atp,
    homeTeamScore: hts,
    awayTeamScore: ats,
  } = game;

  if ([htp, atp, hts, ats].some((score) => typeof score !== 'number')) return;

  return (
    (htp as number) > (atp as number) === (hts as number) > (ats as number)
  );
};

export interface GameTeamData {
  key?: number;
  name?: string;
  avatar?: DefaultAssetResponse & AssetResponse;
  ranking: {
    state?: number | null;
    national?: number | null;
  };
  record?: string | null;
  score?: number | null;
  prediction?: number | null;
  winProbability?: number | null;
  winPercentage?: number | null;
  slug?: string | null;
  urlSlug?: string | null;
}

const getTeamData = (
  isHome: boolean,
  game: ScoreboardResponse,
): GameTeamData => {
  const {
    homeRankingInformation,
    homeTeamOrganization,
    homeTeamScore,
    homeTeamScorePrediction,
    homeTeamWinPercentage,
    homeTeamWinProbability,
    awayRankingInformation,
    awayTeamOrganization,
    awayTeamScore,
    awayTeamScorePrediction,
    awayTeamWinPercentage,
    awayTeamWinProbability,
  } = game || {};

  return {
    key: isHome ? homeTeamOrganization?.key : awayTeamOrganization?.key,
    name: isHome
      ? homeTeamOrganization?.knownAs || homeTeamOrganization?.name
      : awayTeamOrganization?.knownAs || awayTeamOrganization?.name,
    avatar: isHome
      ? homeTeamOrganization?.defaultAsset
      : awayTeamOrganization?.defaultAsset,
    ranking: {
      state: isHome
        ? homeRankingInformation?.stateRank
        : awayRankingInformation?.stateRank,
      national: isHome
        ? homeRankingInformation?.nationalRank
        : awayRankingInformation?.nationalRank,
    },
    record: isHome
      ? homeRankingInformation?.record
      : awayRankingInformation?.record,
    score: isHome ? homeTeamScore : awayTeamScore,
    prediction: isHome ? homeTeamScorePrediction : awayTeamScorePrediction,
    winProbability: isHome ? homeTeamWinProbability : awayTeamWinProbability,
    winPercentage: isHome ? homeTeamWinPercentage : awayTeamWinPercentage,
    slug: isHome ? homeTeamOrganization?.slug : awayTeamOrganization?.slug,
    urlSlug: isHome
      ? homeTeamOrganization?.urlSlug
      : awayTeamOrganization?.urlSlug,
  };
};

export { getGameStatuses, getGameTime, getPredictionOutcome, getTeamData };
